import React, { ReactNode } from 'react';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';

import Carousel from '../carousel/carousel';

const imageProps = {
  className: 'mx-auto gallery-image',
  layout: 'constrained',
  height: 450,
  quality: 95,
  placeholder: 'blurred',
  formats: ['AUTO', 'WEBP', 'AVIF'],
};

const GalleryImageWrapper = ({ children, caption }: { children: ReactNode; caption?: string }) => (
  <div style={{ maxWidth: '95%', minWidth: 300 }} className="relative carousel-child flex-center">
    {children}
    {caption && <span className="absolute bottom-0 left-0 text-center py-2 w-full transparent-bg">{caption}</span>}
  </div>
);

type Image = {
  node: ImageDataLike;
};

const CarouselGallery = ({ images }: { images: Image[] }) => (
  <Carousel>
    {images
      .sort((a, b) => {
        // Slices filename to retrieve number. Will only work with 2 digit number
        const aNum = Number(a.node.childImageSharp.gatsbyImageData.images.fallback.src.slice(-6, -4));
        const bNum = Number(b.node.childImageSharp.gatsbyImageData.images.fallback.src.slice(-6, -4));

        return aNum - bNum;
      })
      .map(image => {
        const src = getImage(image.node);

        return (
          <GalleryImageWrapper key={image.node.childImageSharp.gatsbyImageData.images.fallback.src}>
            {src && <GatsbyImage image={src} {...imageProps} alt="Jonny Ball guitar teacher" />}
          </GalleryImageWrapper>
        );
      })}
  </Carousel>
);

export default CarouselGallery;

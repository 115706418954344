import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Divider from '../components/sections/sectionDivider';
import CarouselGallery from '../components/page-components/gallery/main';
import HeadingSection from '../components/sections/headingSection';
import ImageSection from '../components/sections/imageSection';

const textContent = {
  theBeginning:
    "Born into a family of classical musicians, music is something I've always surrounded by. I started life playing around with piano and trumpet before embarking on my own guitar journey at the start of high school. Since then I’ve never looked back!",
  bloodSweatAndTears:
    'High school music in Stockport was my first foray into playing in bands, in which we had loads of passion met with little skill… Which I’m thankful began to improve during the college years! It was then studying a music degree at Paul McCartney’s Liverpool Institute of Performing Arts, that I honed my craft and met many of my longterm collaborators. Including a songwriting tutorial with Macca himself! ',
  livinTheDream:
    'I’ve since played in bands supporting acts such as ‘The Libertines’, ‘Feeder’, ‘The Rifles’ and many more in venues such as Alexandra Palace, Brixton Academy, Manchester Academy 1, and all manner of others around the UK and Europe. I’ve recorded with music producers such as Ken Nelson (Coldplay, Badly Drawn Boy) and Rich Turvey (Blossoms, The Coral), and had music played on stations such as BBC Radio 2, Radio 6, XFM amongst others. The creative bug in me is still alive and well to this day, which you can follow the progress of as part of my mailing list! ',
  theOneThatNeverGotAway:
    "However, alongside all of this was a constant buzz in the background. My passion and practice in teaching. I've been teaching guitar since before my graduation from music college in 2015. And as well as my workshops around schools, colleges and peripatetic teaching, I have ran my own private practice in Edgeley, Stockport with 30+ weekly students for several years now. In this time, I’ve developed a unique style and a learning pathway that I am passionate can help anyone develop in their journey in guitar, no matter what stage. And, if you're interested, I would like to share it with you!",
};

type ImageProps = {
  layout: 'constrained';
  height: 400;
  quality: 95;
  placeholder: 'blurred';
  formats: ['auto', 'webp', 'avif'];
};

const About = ({ data }) => {
  const imageProps: ImageProps = {
    layout: 'constrained',
    height: 400,
    quality: 95,
    placeholder: 'blurred',
    formats: ['auto', 'webp', 'avif'],
  };

  const images = data ? data?.allFile.edges : null;

  return (
    <Layout>
      <SEO title="About" />
      <HeadingSection
        heading="About Jonny Ball"
        subheading="As you’re here you may be interested to know a bit about
          lil’ old me."
      />
      <Divider />
      <ImageSection
        caption="Supporting Feeder at Manchester Academy"
        backgroundColor="bg-primary-lightest"
        textColor="text-primary"
        heading="STORY SO FAR"
        text="So to cut a long story short (and spare you the details!), follow the short sequence below for my story so far"
        reverse
      >
        <StaticImage {...imageProps} src="../images/about/about-hero.jpg" alt="Jonny Ball teaching guitar" />
      </ImageSection>
      <Divider />
      <ImageSection
        backgroundColor="bg-primary"
        textColor="text-white"
        heading="THE BEGINNING"
        text={textContent.theBeginning}
        caption="Ticklin' the ivories '97"
      >
        <StaticImage {...imageProps} src="../images/about/the-beginning.jpg" alt="Jonny Ball teaching guitar" />
      </ImageSection>
      <Divider />
      <ImageSection
        backgroundColor="bg-primary-lightest"
        textColor="text-primary"
        heading="BLOOD, SWEAT AND TEARS"
        text={textContent.bloodSweatAndTears}
        caption="Me and Macca"
        reverse
      >
        <StaticImage {...imageProps} src="../images/about/graduation.jpg" alt="Jonny Ball teaching guitar" />
      </ImageSection>
      <Divider />
      <ImageSection
        backgroundColor="bg-primary"
        textColor="text-white"
        heading="LIVIN' THE DREAM"
        text={textContent.livinTheDream}
        caption="Jonny rockin' Brixton Academy"
      >
        <StaticImage {...imageProps} src="../images/about/livin-the-dream.jpg" alt="Jonny Ball teaching guitar" />
      </ImageSection>
      <Divider />
      <ImageSection
        backgroundColor="bg-primary-lightest"
        textColor="text-primary"
        heading="THE ONE THAT NEVER GOT AWAY"
        text={textContent.theOneThatNeverGotAway}
        caption="Jonny's home studio in Edgeley, Stockport"
        reverse
      >
        <StaticImage {...imageProps} src="../images/about/never-got-away.jpg" alt="Jonny Ball teaching guitar" />
      </ImageSection>
      <Divider />
      <div className="page-section-padding bg-primary text-white">
        <h4 className="emphasis text-center mb-5">
          Oh, and just incase you’re hungry for more I’ve included a small gallery below! &#128521;
        </h4>
        <CarouselGallery images={images} />
      </div>
    </Layout>
  );
};

export default About;

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
